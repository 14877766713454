import Vue from 'vue';
import Vuex from 'vuex';
import jwt from 'jwt-decode';

Vue.use(Vuex);

export default new Vuex.Store({
  // 相当于组件中的data
  state: {
    token: window.localStorage.getItem('token') === 'undefined' ? 'undefined' : window.localStorage.getItem('token'),
    userMsg: window.localStorage.getItem('userMsg') === 'undefined' ? 'undefined' : JSON.parse(window.localStorage.getItem('userMsg'))
  },
  // 相当于组件中的methods(同步)
  mutations: {
    Token (state, value) {
      state.token = value;
      state.userMsg = jwt(value);
    },
    changeSearchMsg (state, value) {
      state.searchMsg = value;
    },
    RemoveMessage (state, value) {
      state.token = value;
      state.userMsg = value;
    }
  },
  // 提交的是 mutation 而不是直接变更状态，可以包含任意异步操作
  actions: {
    token (context, value) {
      context.commit('Token', value);
    },
    searchMsg (state, value) {
      console.log(value);
      state.commit('changeSearchMsg', value);
    },
    removeMessage (context, value) {
      context.commit('RemoveMessage', value);
    }
  },
  modules: {
  },
  // 相当于组件中的计算属性
  getters: {
  }
});
