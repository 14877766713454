<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    window.addEventListener('unload', this.saveState);
    localStorage.removeItem('token');
    localStorage.removeItem('userMsg');
  },
  methods: {
    saveState () {
      console.log(this.$store.state.token);
      console.log(this.$store.state.userMsg);
      localStorage.setItem('token', this.$store.state.token);
      localStorage.setItem('userMsg', JSON.stringify(this.$store.state.userMsg));
    },
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
#app {
  height: 100%;
}
</style>
