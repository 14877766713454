import VueRouter from 'vue-router';
import store from '../store'
const Test = (r) => require.ensure([], () => r(require('../components/Test.vue')), 'test');
const HomePage = (r) =>require.ensure([], () => r(require('../components/HomePage.vue')), 'HomePage');
const SendMessage = (r) => require.ensure([], () => r(require('../components/SendMessage.vue')), 'SendMessage');
const SendMialbox = (r) => require.ensure([], () => r(require('../components/SendMialbox.vue')), 'SendMialbox');
const EmailList = (r) => require.ensure([], () => r(require('../components/EmailList.vue')), 'EmailList');
const EmailListS = (r) => require.ensure([], () => r(require('../components/EmailListS.vue')), 'EmailListS');
const ClassifyManagement = (r) =>require.ensure([],() => r(require('../components/SystemManagement/ClassifyManagement.vue')),'ClassifyManagement');
const SystemManagement = (r) =>require.ensure([],() => r(require('../components/SystemManagement/SystemManagement.vue')),'SystemManagement');
const MailboxManagement = (r) =>require.ensure([],() => r(require('../components/SystemManagement/MailboxManagement.vue')),'MailboxManagement');
const LoginSign = (r) => require.ensure([], () => r(require('../components/LoginSign/LoginSign.vue')), 'LoginSign');
const LoginPage = (r) => require.ensure([], () => r(require('../components/LoginSign/LoginPage.vue')), 'LoginPage');
const LoginPageS = (r) => require.ensure([], () => r(require('../components/LoginSign/LoginPageS.vue')), 'LoginPageS');
const SignPage = (r) => require.ensure([], () => r(require('../components/LoginSign/SignPage.vue')), 'SignPage');
const UserMessage = (r) => require.ensure([], () => r(require('../components/SystemManagement/UserMessage.vue')), 'UserMessage');
const MyReplay = (r) => require.ensure([], () => r(require('../components/SystemManagement/MyReplay.vue')), 'MyReplay');
const IntroducePage = (r) => require.ensure([], () => r(require('../components/IntroducePage.vue')), 'IntroducePage');
const UserMessages = (r) => require.ensure([], () => r(require('../components/UserMessage.vue')), 'UserMessages');
const UserCheckMsg = (r) => require.ensure([], () => r(require('../pages/HomePage/UserCheckMsg.vue')), 'UserCheckMsg');
const GetMsgDetail = (r) => require.ensure([], () => r(require('../pages/HomePage/GetMsgDetail.vue')), 'GetMsgDetail');

const router = new VueRouter({
  linkActiveClass: 'is-active',
  // mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: 'HomePage/UserCheckMsg'
    },
    {
      path: '/Test',
      name: 'Test',
      component: Test,
      meta: {
        title: '测试'
      }
    },
    {
      path: '/GetMsgDetail',
      name: 'GetMsgDetail',
      component: GetMsgDetail,
    },
    {
      path: '/HomePage',
      name: 'HomePage',
      component: HomePage,
      meta: {
        title: '信箱首页'
      },
      children: [
        {
          path: '/',
          redirect: 'UserCheckMsg'
        },
        {
          path:'UserCheckMsg',
          name:'UserCheckMsg',
          component:UserCheckMsg,
          meta: {
            title: ''
          }
        },
        //介绍的页面
        {
          path: 'IntroducePage',
          name: 'IntroducePage',
          component: IntroducePage,
          meta: {
            title: ''
          }
        },
        //写信的页面
        {
          path: 'SendMialbox',
          name: 'SendMialbox',
          component: SendMialbox,
          meta: {
            title: '写信的页面',
            isGuard: 1
          }
        },
        //用户信息
        {
          path: 'UserMessages',
          name: 'UserMessages',
          component: UserMessages,
          meta: {
            title: '个人信息',
            isGuard: 1
          }
        },
        {
          path: 'EmailListS',
          name: 'EmailListS',
          component: EmailListS,
          meta: {
            title: '信件列表',
            isGuard: 1
          }
        },
        {
          path: 'Login',
          name: 'Login',
          component: LoginPageS,
          meta:{
            title: '登录'
          },
          children:[
            {
              path: '/',
              redirect: 'LoginPage'
            },
            {
              path:'LoginPage',
              name:'LoginPage',
              component:LoginPage
            },
            {
              path:'SignPage',
              name:'SignPage',
              component:SignPage
            }
          ]
        },
      ]
    },
    // 后台管理系统
    {
      path: '/SystemManagement',
      name: 'SystemManagement',
      component: SystemManagement,
      meta: {
        title: '校长邮箱管理系统',
        isGuard: 2
      },
      children: [
        {
          path: '/',
          redirect: 'MailboxManagement'
        },
        {
          path: 'ClassifyManagement',
          name: 'ClassifyManagement',
          component: ClassifyManagement,
          meta: {
            title: '分类管理',
            isGuard: 2
          }
        },
        {
          path: 'MailboxManagement',
          name: 'MailboxManagement',
          component: MailboxManagement,
          meta: {
            title: '邮箱管理',
            isGuard: 2
          }
        },
        {
          path: 'UserMessage',
          name: 'UserMessage',
          component: UserMessage,
          meta: {
            title: '个人资料',
            isGuard: 2
          }
        },
        {
          path: 'MyReplay',
          name: 'MyReplay',
          component: MyReplay,
          meta: {
            title: '我的回复',
            isGuard: 2
          }
        }
      ]
    }
  ]
});
// 全局拦截
router.beforeEach((to, from, next) => {
  if (to.meta.isGuard !== undefined) {
    //判断是否登录
    if (store.state.token === undefined || store.state.token === null || store.state.token === 'null' || store.state.token === 'undefined') {
      //如果没有登录则返回登录页面
      next('/HomePage/Login')
    } else {
      if(to.meta.isGuard === 1){
        next();
      }else{
        //管理员
        if(store.state.userMsg.role ==='1'){
          next();
        }else{
          next('/HomePage/Login')
        }
      }
    }
  } else {
    //用户都可以进入
    next()
  }
})
export default router;
